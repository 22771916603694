

















































import { Component, Vue } from 'vue-property-decorator';
import emoji from '@/assets/data/emoji';
@Component
export default class Emoji extends Vue {
  get tabName(): string | (string | null)[] {
    return this.$route.query.tabName || '';
  }
  list = emoji;
}
