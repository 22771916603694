

















import { Component, Vue } from 'vue-property-decorator';
import game from '@/assets/data/game';
@Component
export default class Game extends Vue {
  list = game;
}
