import instance from './base';

interface PlayInfo {
  result: number;
  title: string;
  videos: { playUrl: string; episodeName: string }[];
}

export const getPlayInfo = (resourceId: string, resourceType: string, mkey: string, playPassword = ''): Promise<PlayInfo> => {
  return instance.post('/rest/pc-direct/hapame/playPage', {
    resourceId,
    resourceType,
    mkey,
    playPassword,
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCdnsafety = (deviceId: string, uid: string): Promise<any> => {
  const params = {
    platform: 5,
    app_version: '1.0.0',
    device_id: String(deviceId),
    user_id: String(uid),
  };
  return instance.post('/safetyid', params, {
    baseURL: 'http://cdnsafety.idgen.internal:8899',
  });
};
