
























import { Component, Vue } from 'vue-property-decorator';
import tabs from '@/assets/data/tabs';

@Component
export default class Header extends Vue {
  tabs: { tabName: string; url: string; key: string }[] = tabs;
  currentIndex = 0;

  mounted() {
    this.updateCurrentIndex();
  }

  get bannerDisplay(): boolean {
    return this.$route.name === 'Home';
  }

  updateCurrentIndex(): void {
    const currentQuery = this.$route.query;
    const foundIndex = this.tabs.findIndex((tab) => tab.tabName === currentQuery?.tabName);
    if (foundIndex !== -1) {
      this.currentIndex = foundIndex;
    }
  }

  routerHandle({ url, tabName, key }: { url: string; tabName: string; key: string }, index: number): void {
    this.currentIndex = index;
    this.$router.push({
      path: key ? `/list/${key}` : url,
      query: {
        tabName,
      },
    });
  }

  goHomePage(): void {
    this.$router.push({ path: '/' });
  }
}
