








import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/common/header.vue';
import Footer from '@/components/common/footer.vue';

@Component({
  components: {
    Header,
    Footer,
  },
})
export default class App extends Vue {}
