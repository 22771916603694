export default [
  {
    tabName: '首页',
    url: '/',
    key: 'home',
  },
  // {
  //   tabName: '漫画',
  //   url: '/comics',
  //   key: '',
  // },
  {
    tabName: '动画',
    url: '/',
    key: 'animation',
  },
  {
    tabName: '影视',
    url: '/',
    key: 'movie',
  },
  {
    tabName: '文艺',
    url: '/',
    key: 'literature',
  },
  {
    tabName: '娱乐',
    url: '/',
    key: 'recreation',
  },
  {
    tabName: '科技',
    url: '/',
    key: 'technology',
  },
  {
    tabName: '财经',
    url: '/',
    key: 'finance',
  },
  {
    tabName: '体育',
    url: '/',
    key: 'sport',
  },
  {
    tabName: '教育',
    url: '/',
    key: 'education',
  },
  {
    tabName: '电视剧',
    url: '/',
    key: 'tvPlay',
  },
  {
    tabName: '电影',
    url: '/',
    key: 'film',
  },
  // {
  //   tabName: '动漫表情',
  //   url: '/emoji',
  //   key: '',
  // },
  // {
  //   tabName: '游戏中心',
  //   url: '/gamecenter',
  //   key: '',
  // },
  // {
  //   tabName: '下载客户端',
  //   url: '/download',
  //   key: '',
  // },
];
