























import { Component, Vue, Watch } from 'vue-property-decorator';
import { getContentList } from '../service/list';
@Component
export default class List extends Vue {
  get pageKeyDisplay(): string {
    return this.$route.params.key;
  }
  get tabName(): string | (string | null)[] {
    return this.$route.query.tabName || '';
  }
  list: Array<{
    resourceId: string;
    resourceType: number;
    coverUrl: string;
    title: string;
  }> = [];
  page = 0;
  async created(): Promise<void> {
    this.getList(this.page);
  }
  async getList(page: number): Promise<void> {
    this.page = page;
    const res = await getContentList(this.page, this.pageKeyDisplay);
    this.list = res.feed;
  }
  jumpToPage({ resourceId, resourceType }: { resourceId: string; resourceType: number }): void {
    this.$router.push({
      path: `/detail/${resourceType}/${resourceId}`,
    });
  }

  @Watch('$route.params')
  routeChange(): void {
    this.getList(0);
  }
}
