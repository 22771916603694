




































import { Component, Ref, Vue } from 'vue-property-decorator';
import { getPlayInfo } from '@/service/detail';
// 直接用browser模式的包会有问题
import { generateMKey, generateSafetyId } from '@ac/anti-leech/dist/anti-leech.cjs';
// 播放器内核
import PlayerCore, { PlayerType } from '@xplayer/player-core';
// 播放器控制栏组件
import controlsPlugin from '@xplayer/player-plugin-controls';

@Component
export default class Detail extends Vue {
  /**
   * 页面期待传入 resourceId & resourceType
   * created 请求数据，mounted 挂载
   */
  get resourceId(): string {
    return this.$route.params.resourceId;
  }

  get resourceType(): string {
    return this.$route.params.resourceType;
  }

  @Ref() player!: HTMLElement;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  webPlayer: any;
  currentVideo = 1;

  // 页面数据
  title = '';
  videos: { playUrl: string; episodeName: string }[] = [];
  firstLock = true;
  showDialog = false;
  passwordError = false;
  playPassword = '';
  isLoading = true;
  mkey = '';
  isClickSubmit = false;
  isInitPlayer = false;

  currentVideoInfo: { playUrl: string; episodeName: string } | undefined = undefined;

  async created(): Promise<void> {
    this.mkey = await this.getMkey();
    await this.getInfo();
  }

  initChangeUrl(url: string | undefined): void {
    if (this.firstLock && url) {
      this.changeUrl(url, 0);
      this.firstLock = false;
    }
  }

  changeUrl(url: string, index: number): void {
    // 播放 & 切换视频
    this.currentVideo = index;
    this.webPlayer?.load(`${url}`);
  }

  // 解析cookie具体值
  getCookie(name: string): string {
    let arr;
    const reg = new RegExp(`(^| )${name}=([^;]*)(;|$)`);
    if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
    return '';
  }

  // 生成Mkey
  async getMkey(): Promise<string> {
    const did = this.getCookie('_did') || '';
    const uid = this.getCookie('auth_key') || '';
    const safetyId = generateSafetyId(did, uid);
    const isMobile = false;
    const platform = isMobile ? 4 : 5;

    return generateMKey(platform, String(decodeURIComponent(safetyId)), '1.0.0', String(did), String(uid));
  }

  initPlayer(): void {
    this.$nextTick(() => {
      this.webPlayer = new PlayerCore(this.player, {
        type: PlayerType.VIDEO,
        plugins: [new controlsPlugin({})],
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).player = this.webPlayer;

      this.initChangeUrl(this.currentVideoInfo?.playUrl);
    });
  }

  async getInfo(): Promise<void> {
    const { result, title, videos } = await getPlayInfo(this.resourceId, this.resourceType, this.mkey, this.playPassword);
    this.isLoading = false;
    this.showDialog = false;
    this.passwordError = false;
    if (result === 0) {
      this.playPassword = '';
      this.title = title;
      this.videos = videos;
      this.videos.length && (this.currentVideoInfo = this.videos[0]);
      if (!this.isInitPlayer) {
        this.isInitPlayer = true;
        this.initPlayer();
      }
    } else if (result === 132001) {
      // 展示弹窗
      this.showDialog = true;
      this.passwordError = true;
    }
  }

  async submitPassword(): Promise<void> {
    this.isClickSubmit = true;
    this.passwordError = false;
    await this.getInfo();
  }
  // 销毁播放器
  beforeDestroy(): void {
    console.log(1312312);
    this.webPlayer?.destroy();
  }
}
