export default [
  {
    cloumn: 1,
    item: [
      { id: 1, text: '案发现场' },
      { id: 2, text: '贫民站大嘴的' },
      { id: 3, text: '第一次亲密接触' },
      { id: 4, text: '编辑部的故事' },
      { id: 5, text: '家有儿女' },
    ],
  },
  {
    cloumn: 2,
    item: [
      { id: 6, text: '人证' },
      { id: 7, text: '翻滚吧蛋炒饭' },
      { id: 8, text: '背叛' },
      { id: 9, text: '上海滩' },
      { id: 10, text: 'DA师' },
    ],
  },
];
