









import { Component, Vue } from 'vue-property-decorator';
@Component
export default class ComicDetail extends Vue {
  get part(): string {
    return this.$route.params.part;
  }
  get total(): number {
    return Number(this.$route.params.total);
  }
}
