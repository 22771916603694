














import { Component, Vue } from 'vue-property-decorator';
import comicList from '@/assets/data/comic';
@Component
export default class Comics extends Vue {
  list = comicList;

  jumpToPage({ part, total }: { part: number; total: number }): void {
    this.$router.push({
      path: `/comic/detail/${part}/${total}`,
    });
  }
}
