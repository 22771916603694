import Axios, { AxiosError } from 'axios';
import qs from 'qs';

const isDev = process.env.NODE_ENV === 'development' || !process.env.NODE_ENV;
const instance = Axios.create({
  baseURL: isDev ? '/api' : '',
  timeout: 3000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

instance.interceptors.request.use(
  (config) => {
    // 统一处理 数据
    if (config.method === 'post') {
      config.data = qs.stringify(config.data);
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

export default instance;
