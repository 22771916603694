





























import { Component, Vue } from 'vue-property-decorator';
import { getContentList } from '../service/list';
interface videoInfo {
  resourceId: string;
  resourceType: number;
  coverUrl: string;
  title: string;
}
@Component
export default class Home extends Vue {
  get pageKeyDisplay(): string {
    return this.$route.params.key;
  }
  get tabName(): string | (string | null)[] {
    return this.$route.query.tabName || '';
  }
  get firstItem(): videoInfo {
    return this.list?.[0];
  }
  get preList(): Array<videoInfo> {
    return this.list?.slice(1, 5);
  }
  get restList(): Array<videoInfo> {
    return this.list?.slice(5);
  }

  list: Array<videoInfo> = [];

  async created(): Promise<void> {
    this.getList();
  }
  async getList(): Promise<void> {
    const res = await getContentList(1, 'home');
    this.list = res.feed;
  }
  jumpToPage({ resourceId, resourceType }: { resourceId: string; resourceType: number }): void {
    this.$router.push({
      path: `/detail/${resourceType}/${resourceId}`,
    });
  }
}
