import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import List from '../views/List.vue';
import Detail from '../views/Detail.vue';
import Download from '../views/Download.vue';
import Game from '../views/Game.vue';
import Comics from '../views/Comics.vue';
import ComicDetail from '../views/ComicDetail.vue';
import Emoji from '../views/Emoji.vue';
import Page404 from '../views/404.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    alias: '/list/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/list/:key',
    name: 'list',
    component: List,
  },
  {
    path: '/detail/:resourceType/:resourceId',
    name: 'detail',
    component: Detail,
  },
  {
    path: '/download',
    name: 'download',
    component: Download,
  },
  {
    path: '/gamecenter',
    name: 'game',
    component: Game,
  },
  {
    path: '/comics',
    name: 'comics',
    component: Comics,
  },
  {
    path: '/comic/detail/:part/:total',
    name: 'comicsdetail',
    component: ComicDetail,
  },
  {
    path: '/emoji',
    name: 'emoji',
    component: Emoji,
  },
  {
    path: '*',
    name: '404',
    component: Page404,
  },
];

// const isDev = process.env.NODE_ENV === 'development' || !process.env.NODE_ENV;
// const baseURL = isDev ? process.env.BASE_URL : '/acfun/hapame/';
const baseURL = process.env.baseURL;

const router = new VueRouter({
  mode: 'history',
  base: baseURL,
  routes,
});

export default router;
