export default [
  {
    part: 1,
    total: 12,
    name: '十二星座对对碰',
    section: '购物风波',
    sort: '第一话',
  },
  {
    part: 2,
    total: 9,
    name: '十二星座对对碰',
    section: '天上掉馅饼',
    sort: '第二话',
  },
  {
    part: 3,
    total: 9,
    name: '十二星座对对碰',
    section: '非典来袭',
    sort: '第三话',
  },
];
